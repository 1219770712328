<template>
  <section>
    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>Reporte de operaciones</h1>

        <v-btn
          color="success"
          @click="download"
        >
          <v-icon left>mdi-download</v-icon>
          Descargar
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="mb-4">
      <v-card-title>Filtros</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <TextField
              v-model="filters.number_of_products"
              label="Número de productos"
              prepend-icon="mdi-hanger"
              rules=""
            />
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <TextField
              v-model="filters.created_at"
              label="Fecha"
              prepend-icon="mdi-calendar"
              rules=""
              type="date"
            />
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <TextField
              v-model="filters.total"
              label="Total"
              prepend-icon="mdi-currency-usd"
              rules=""
            />
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <SelectField
              v-model="filters.status"
              :items="status"
              item-text="name"
              item-value="name"
              label="Estatus"
              prepend-icon="mdi-format-list-bulleted"
              rules=""
            />
          </v-col>

          <v-col v-if="can('owner')" cols="12" md="6" lg="3">
            <SelectField
              v-model="filters.office"
              :items="offices"
              item-text="name"
              item-value="uuid"
              label="Sucursal"
              prepend-icon="mdi-store"
              rules=""
            />
          </v-col>

          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              color="primary"
              @click="list"
            >
              <v-icon left>mdi-magnify</v-icon>
              Filtrar
            </v-btn>

            <v-btn
              class="ml-2"
              color="warning"
              @click="resetFilters"
            >
              <v-icon left>mdi-delete</v-icon>
              Limpiar filtros
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          hide-details
          single-line
        />
      </v-card-title>

      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        :options.sync="options"
        :search="search"
        :server-items-length="total"
      >
        <template v-slot:item.total="{ value }">
          {{ value | currency }}
        </template>

        <template v-slot:item.created_at="{ value }">
          {{ value | date }}
        </template>

        <template v-slot:item.index="{ item }">
          {{ getItemIndex(item) }}
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
// API
import OfficeAPI from '@/api/office';
import OrderAPI from '@/api/order';

// Mixins
import TableMixin from '@/mixins/TableMixin';

// Utils
import { downloadFile } from '@/utils/file';

const initialFilters = () => ({
  created_at: null,
  number_of_products: null,
  office: null,
  status: null,
  total: null,
});

export default {
  name: 'OperationReport',

  mixins: [
    TableMixin,
  ],

  data: () => ({
    headers: [
      { text: '#', value: 'index' },
      { text: 'Clave', value: 'number' },
      { text: 'Cliente', value: 'customer.full_name' },
      { text: 'No. de productos', value: 'number_of_products' },
      { text: 'Fecha', value: 'created_at' },
      { text: 'Sucursal', value: 'office.name' },
      { text: 'Total', value: 'total' },
      { text: 'Estatus', value: 'status' },
    ],
    filters: initialFilters(),
    offices: [],
    status: [],
  }),

  async created() {
    this.api = new OrderAPI();
    this.officeAPI = new OfficeAPI();
    await this.list();
    await this.getOperations();

    if (this.can('owner')) {
      await this.getOffices();
    }
  },

  methods: {
    async list() {
      this.loading = true;
      try {
        const query = {
          ...this.getQuery(),
          ...this.filters,
        };
        this.items = await this.api.report(query);
        this.total = this.items.length;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.loading = false;
    },

    getItemIndex(item) {
      return this.items.indexOf(item) + 1;
    },

    resetFilters() {
      this.filters = initialFilters();
      this.list();
    },

    download() {
      const headers = this.headers.map((header) => header.text).join(',');
      const items = this.$refs.table.selectableItems.map(
        (item, index) => [
          index + 1,
          item.number,
          item.customer.full_name,
          item.number_of_products,
          item.created_at,
          item.office.name,
          item.total,
          item.status,
        ].join(','),
      ).join('\n');

      // Add BOM to enforce UTF-8
      const csv = `\uFEFF${headers}\n${items}`;
      downloadFile(csv, 'Reporte de operaciones.csv');
    },

    async getOperations() {
      this.$store.commit('showLoader');
      try {
        this.status = await this.api.operations();
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getOffices() {
      this.$store.commit('showLoader');
      try {
        const res = await this.officeAPI.list();
        this.offices = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
